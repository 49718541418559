@import 'nprogress/nprogress.css';

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f2f5; /* 设置背景颜色 */
  color: #333; /* 设置文本颜色 */
  // height: 100%;
  width: 100%;
  font-size: 14px;
}
body {
  // background-color: #17181a;
  // color: #fff;
  background-color: var(--color-bg-1);
  color: var(--color-text-1);
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
}

.arco-image-img {
  width: 100%;
}

.app {
  .app__content {
    /* 从上到下白色渐变到灰色 */
    // background-color: #17181a;
  }
  .app__header {
    // background-color: #17181c;
    color: #fff;
    border-bottom: 1px solid var(--color-fill-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    &--logo {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      img {
        width: 10rem;
      }
    }
  }
}
