.feedback--content {
  overflow: hidden;
  overflow-y: auto;
  height: calc(80vh - 100px);
  &--card {
    border: 1px solid var(--color-border-2);
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    padding: 0.5rem 0.8rem;
    box-sizing: border-box;
    width: 100%;
    &__title {
      font-size: 1rem;
      font-weight: bold;
    }
    &__meta {
      padding-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      color: var(--color-text-3);
      font-size: 0.8rem;
    }
    &__reply {
      margin-top: 0.5rem;
      border-top: 1px solid var(--color-border-2);
      padding: 0.5rem 0 0 0;
      &--content {
        color: var(--color-text-2);
        font-size: 0.9rem;
      }
      &--meta {
        margin: 0.3rem 0 0 0;
        display: flex;
        justify-content: space-between;
        color: var(--color-text-3);
        font-size: 0.8rem;
      }
    }
  }
}

.arco-modal-content {
  padding: 0.8rem;
}
