.layout {
  width: 100%;
  height: 100%;
}

.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh);
}
